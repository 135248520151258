const plyty_warstwowe = [
  {
    name: "Płyta warstwowa GS S",
    description: "Płyta ścienna poliizocyjanurowa",
    image: "products/plyty_war/war_gs_s.png"
  },
  {
    name: "Płyta warstwowa GS U",
    description: "Płyta ścienna poliizocyjanurowa",
    image: "products/plyty_war/war_gs_u.png"
  },
  {
    name: "Płyta warstwowa GS CH",
    description: "Plyta chłodnicza poliizocyjanurowa",
    image: "products/plyty_war/gs_ch.png"
  },
  {
    name: "Płyta warstwowa GS PIR D",
    description: "Płyta dachowa poliizocyjanurowa",
    image: "products/plyty_war/war_gs_d.png"
  },
  {
    name: "Płyta warstwowa GS S MAX",
    description: "Płyta ścienna",
    image: "products/plyty_war/gs_s_max.png"
  },
  {
    name: "Płyta warstwowa GS U MAX",
    description: "Płyta ścienna",
    image: "products/plyty_war/gs_u_max.png"
  },
  {
    name: "Płyta warstwowa GS CH MAX",
    description: "Płyta chłodnicza",
    image: "products/plyty_war/gs_ch_max.png"
  },
  {
    name: "Płyta warstwowa GS PIR D MAX",
    description: "Ekstremalnie trwałe płyty warstwowe, przeznaczone do najbardziej wymagających zastosowań budowlanych. Gwarancja długotrwałej ochrony.",
    image: "products/plyty_war/gs_d_max.png"
  },
  {
    name: "Płyta warstwowa GS FIRE CH",
    description: "Płyta PIR EI 60 Klasa reakcji na ogień B-S1, d0",
    image: "products/plyty_war/gs_fire_ch.png"
  }
];

export default plyty_warstwowe;
