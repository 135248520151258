import React from 'react';
import Card from './Card';
import plyty_pir from './config/PlytyPirConfig';

const PlytyPIR = () => (
  <div>
    <h1>Oferta: </h1>
    <div className="product-list">
      {plyty_pir.map((product, index) => (
        <Card key={index} product={product} />
      ))}
    </div>
  </div>
);

export default PlytyPIR;
