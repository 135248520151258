const plyty_pir = [
  {
    name: "Płyta PIR AL",
    description: "Są to panele termoizolacyjne z rdzeniem wykonanym ze sztywnej pianki PIR. Każda płyta jest pokryta obustronnie warstwową okładziną gazoszczelną, składającą się z aluminium (AL), papieru oraz polietylenu.",
    image: "products/plyty_pir/plyta_izo_AL.png"
  },
  {
    name: "Płyta PIR AL OSB",
    description: "Materiał konstrukcyjny z rdzeniem z pianki PIR, pokryty warstwową okładziną aluminiową, papierem oraz polietylenem. Dodatkowo wyposażona w płytę OSB o grubości 8-22 mm, zapewniającą solidność i wytrzymałość konstrukcji. Idealna do zastosowań zarówno wewnątrz, jak i na zewnątrz budynków.",
    image: "products/plyty_pir/pir_al_osb.png"
  },
  {
    name: "Płyta PIR AL GK",
    description:"Termoizolacyjny materiał z rdzeniem z pianki PIR, pokryty warstwową okładziną aluminiową oraz gipsokartonem (GK). Zapewnia efektywną izolację termiczną oraz możliwość prostego montażu na powierzchniach wewnętrznych, dzięki obecności gipsokartonu.",
    image: "products/plyty_pir/plyta_izo_pir_al_gk.png"
  },
  {
    name: "Płyta PIR AL",
    description: "Płyta PIR AL to termoizolacyjny materiał z rdzeniem z pianki PIR, pokryty warstwową okładziną aluminiową. Oferuje doskonałą izolację termiczną w lekkiej i trwałej konstrukcji.",
    image: "products/plyty_pir/plyta_izo_AL.png"
  },
  {
    name: "Płyta PIR AGRO AL",
    description: "Płyta PIR AGRO AL to termoizolacyjny materiał z rdzeniem z pianki PIR, pokryty warstwową okładziną aluminiową. Idealna do zastosowań w rolnictwie, zapewniająca skuteczną izolację termiczną.",
    image: "products/plyty_pir/plyta_izo_agro_al.png"
  },
  {
    name: "Płyta PIR ETX",
    description: "Płyta PIR ETX to wyjątkowy materiał termoizolacyjny, stworzony z wysokiej jakości pianki PIR. Dzięki unikalnym właściwościom termicznym doskonale izoluje, chroniąc przed utratą ciepła. Idealna do zastosowań budowlanych, zapewniająca efektywną ochronę termiczną.",
    image: "products/plyty_pir/pir_etx.png"
  },
  {
    name: "Płyta IZOPROOF",
    description: "Innowacyjny materiał izolacyjny, który doskonale sprawdza się w zastosowaniach budowlanych. Wykonana z wysokiej jakości materiałów, zapewnia skuteczną izolację termiczną, chroniąc przed utratą ciepła. To idealne rozwiązanie dla osób poszukujących efektywnych metod poprawy efektywności energetycznej w swoich projektach.",
    image: "products/plyty_pir/izoproof.png"
  },
  {
    name: "Płyta PIR GK AL GK",
    description: "Rdzeń z pianki PIR pokryty jest dwustronną, gazoszczelną okładziną warstwową z papieru, aluminium oraz płyt GK o grubości 12,5 mm z obu stron (jedna płyta). Warstwa adhezyjna oddziela płytę z okładziną aluminiową od płyt GK.",
    image: "products/plyty_pir/izo_pir_gk_al_gk.png"
  },
  {
    name: "Płyta PIR AGRO P REV",
    description: "Płyty są zabezpieczone dwustronną okładziną gazoszczelną, składającą się z warstwowego laminatu aluminium i polietylenu (Agro P), z warstwą aluminiową zwróconą ku rdzeniowi PIR. Przeznaczone są do stosowania w kontakcie z materiałami, które mogą reagować z aluminium.",
    image: "products/plyty_pir/izo_pir_agro_p_rev.png"
  }
];

export default plyty_pir;
