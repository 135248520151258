
const Opinie = () =>{
    return (
        <div>
        <h2>Co mówią nasi klienci?</h2>
              <p>
              Sprawdź, co nasi klienci z Nowego Sącza i okolic mówią o naszych produktach i usługach. Zobacz opinie klientów po zakupie płyt PIR w Nal-Panel!    
              </p>
              <div class="opinia">
        <img src="clients/client_male_basic.png" alt="Zdjęcie Jan Kowalski" class="client-pic"/>
        <div class="details">
            <p>"Płyty PIR zakupione w Nal-Panel znacząco poprawiły komfort cieplny w naszym domu. Szybka dostawa i profesjonalne doradztwo!"</p>
            <p class="autor"> - Jan Kowalski, Nowy Sącz</p>
            <div class="review">
                ★★★★☆
            </div>
        </div>
    </div>
    <div class="opinia">
        <img src="clients/client_female_basic.png" alt="Zdjęcie Anna Nowak" class="client-pic"/>
        <div class="details">
            <p>"Bardzo solidne płyty izolacyjne. Polecam każdemu, kto szuka skutecznej izolacji w Nowym Sączu."</p>
            <p class="autor"> - Anna Nowak, Krynica-Zdrój</p>
            <div class="review">
                ★★★★★
            </div>
        </div>
    </div>
    </div>
    );
}
export default Opinie;
