import {useState} from "react";
import { Link } from "react-router-dom";
import ContactForm from "./ContactForm";
import ScrollToTop from "./utils/ScrollToTop"

const ContactComponent = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  const closeMobileNav = () => {
    setMobileNavOpen(false);
  };

  return (
    <div>
      <header>
        <div className="header-content">
          <div className="logo">
            <a href="/">
              <img src="/nal_panel.jpg" alt="Logo" />
            </a>
          </div>
          <div
            className={`mobile-nav-icon ${isMobileNavOpen ? "open" : ""}`}
            onClick={toggleMobileNav}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <nav className="desktop-view">
            <ul>
              <li>
                <Link to="/">O nas</Link>
              </li>
              <li>
                <Link to="/plyty-pir">Płyty PIR</Link>
              </li>
              <li>
                <Link to="/plyty-warstwowe">Płyty warstwowe</Link>
              </li>
              <li>
                <Link to="#">Kontakt</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className={`mobile-view ${isMobileNavOpen ? "open" : ""}`}>
          <ul>
            <li>
              <Link to="/">O nas
              </Link>
            </li>
            <li>
              <Link to="/plyty-pir" onClick={closeMobileNav}>
                Płyty PIR
              </Link>
            </li>
            <li>
              <Link to="/plyty-warstwowe" onClick={closeMobileNav}>
                Płyty Warstwowe
              </Link>
            </li>
            <li>
              <Link to="/kontakt" onClick={closeMobileNav}>
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
      </header>
      <main>
        <section id="contact">
          <h2>Skontaktuj się z nami</h2>
          <p id="contact-text">
          Masz pytania dotyczące naszych produktów? Odwiedź nasz punkt w Nowym Sączu lub skontaktuj się telefonicznie
          </p>
          <div id="contact-wrapper">
          <div id="contact-info">
            <div id="contact-content">
              <h3>Dane kontaktowe</h3>
             <p>
             Nal-Panel Płyty warstwowe i izolacyjne <br/>
             <b className="b-style">NIP:</b> 7343636451
             </p>
              <p>
                <b className="b-style">Email: </b>
                <a href="mailto:biuro.nalpanel@gmail.com">
                  biuro.nalpanel@gmail.com
                </a>
              </p>
              <p>
                <b className="b-style">Telefony:</b> <br />
                +48 881 293 888 <br />
                +48 600 059 889
              </p>
              <p>
                <b className="b-style">Adres:</b>
                <br /> Biczyce Górne 158 <br /> 33-395 <br />
              </p>
            </div>
          </div>
          <ContactForm />
          </div>
        </section>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3496.268417299164!2d20.624184000000003!3d49.62895760000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473dfb532f1747d5%3A0xc0aa005e4f85b943!2sNal-Panel%20P%C5%82yty%20warstwowe%20i%20izolacyjne%2C%20p%C5%82yty%20PIR!5e1!3m2!1spl!2spl!4v1726664628518!5m2!1spl!2spl" width="100%" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </main>
      <footer id="footer">
        <div className="footer-content">
          <div className="logo">
            <img src="/nal_panel_removedbg.png" alt="Footer Logo" />
          </div>
          <p>
            &copy; {new Date().getFullYear()} Nal-Panel. Wszelkie prawa
            zastrzeżone.
          </p>
        </div>
      </footer>
      <ScrollToTop/>
    </div>
  );
};

export default ContactComponent;
