import React, {useEffect} from 'react';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import ScrollToTopAfterNavigating from './components/utils/ScrollToTopAfterNavigating';
import PlytyPIRComponent from './components/products/PlytyPIRComponent';
import PlytyWarstwoweComponent from './components/products/PlytyWarstwoweComponent';
import ContactComponent from './components/ContactComponent';
import SFContent from './components/analytics/SFContent'

function App() {
  useEffect(() => {
    const tagManagerArgs = {
        gtmId: 'GTM-PVZRVTLL'
    };
    TagManager.initialize(tagManagerArgs);
}, []);
  return (
    <Router>
      <SFContent/>
      <ScrollToTopAfterNavigating/>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/plyty-warstwowe" element={<PlytyWarstwoweComponent />} />
          <Route path="/plyty-pir" element={<PlytyPIRComponent />} />
          <Route path="/kontakt" element={<ContactComponent />} />
          <Route path="*" element={<Home/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
