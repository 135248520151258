import React, { useState } from 'react';

const FAQ = () => {
    const [openQuestionIndex, setOpenQuestionIndex] = useState(0); 

    const handleToggle = (index) => {
        setOpenQuestionIndex(openQuestionIndex === index ? null : index);
    };

    const faqData = [
        {
            question: "Jakie są zalety płyt PIR w porównaniu do innych materiałów izolacyjnych?",
            answer: "Płyty PIR oferują doskonałe właściwości izolacyjne przy mniejszej grubości materiału, są odporne na wilgoć, mają wysoką odporność na ściskanie i cechują się długą żywotnością."
        },
        {
            question: "Czy płyty PIR nadają się do izolacji dachu?",
            answer: "Tak, płyty PIR są idealnym wyborem do izolacji dachów, zarówno płaskich, jak i skośnych, ze względu na swoje właściwości termoizolacyjne i odporność na wilgoć."
        },
        {
            question: "Jaką grubość płyt PIR wybrać do ocieplenia ścian?",
            answer: "Grubość płyt PIR do ocieplenia ścian zależy od wymagań izolacyjnych budynku. Typowo stosuje się płyty o grubości od 50 do 150 mm."
        }
    ];

    return (
        <div className="faq-section">
            <h2>Najczęściej Zadawane Pytania</h2>
            {faqData.map((item, index) => (
                <div className="faq-item" key={index}>
                    <button className="faq-question" onClick={() => handleToggle(index)}>
                        {item.question}
                    </button>
                    {openQuestionIndex === index && (
                        <div className="faq-answer">
                            <p>{item.answer}</p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default FAQ;
