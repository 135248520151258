const ContactForm = () =>{
  return (
        <form id="contact-form" target="_blank" action="https://formsubmit.co/biuro.nalpanel@gmail.com" method="POST">
            <label>
              Imię i nazwisko:
              <input type="text" name="name"  required/>
            </label>
            <label>
              Adres e-mail:
              <input type="email" name="email" required/>
            </label>
            <label>
              Numer telefonu:
              <input type="tel" name="phoneNumber"/>
            </label>
            <label>
              Wiadomość:
              <textarea name="message" required></textarea>
            </label>
            <button type="submit">Wyślij</button>
          </form>
    )
}

export default ContactForm;