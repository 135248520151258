import React, { useEffect } from 'react';

const SFContent = () => {
    const gtag = (...args) => window.gtag?.(...args);

    useEffect(() => {
    console.log('Consent closed status:', localStorage.getItem("sf-consentclosed"));
      if (!localStorage.getItem("sf-consentclosed")) {
        lockScroll();
        showConsentOverlay(true);
      }
  
      updateCheckboxesFromStorage();
  
      document.getElementById('sfAgreeAllButton')?.addEventListener("click", handleAgreeAll);
      document.getElementById("sfDetailsButton")?.addEventListener("click", toggleDetails);
      document.getElementById("sfCloseDetailsButton")?.addEventListener("click", handleCloseDetails);
  
      const toggleConfigs = [
        { id: "sfAdStorageToggle", updateFn: updateAdStorage },
        { id: "sfAdUserDataToggle", updateFn: updateAdUserData },
        { id: "sfAdPersonalizationToggle", updateFn: updateAdPersonalization },
        { id: "sfAnalyticsStorageToggle", updateFn: updateAnalyticsStorage },
        { id: "sfFunctionalityStorageToggle", updateFn: updateFunctionalityStorage },
        { id: "sfPersonalizationStorageToggle", updateFn: updatePersonalizationStorage },
        { id: "sfSecurityStorageToggle", updateFn: updateSecurityStorage },
      ];
  
      toggleConfigs.forEach(({ id, updateFn }) => {
        document.getElementById(id)?.addEventListener("change", (e) => updateFn(e.target.checked));
      });
  
      return () => {
        document.getElementById("sfAgreeAllButton")?.removeEventListener("click", handleAgreeAll);
        document.getElementById("sfDetailsButton")?.removeEventListener("click", toggleDetails);
        document.getElementById("sfCloseDetailsButton")?.removeEventListener("click", handleCloseDetails);
        toggleConfigs.forEach(({ id }) => {
          document.getElementById(id)?.removeEventListener("change", (e) => updateConsent(id, e.target.checked));
        });
      };
    }, []);
  
    const updateConsent = (type, granted) => {
      localStorage.setItem(type, granted);
      gtag('consent', 'update', { [type]: granted ? 'granted' : 'denied' });
    };
  
    const updateAdStorage = (granted) => updateConsent('ad_storage', granted);
    const updateAdUserData = (granted) => updateConsent('ad_user_data', granted);
    const updateAdPersonalization = (granted) => updateConsent('ad_personalization', granted);
    const updateAnalyticsStorage = (granted) => updateConsent('analytics_storage', granted);
    const updateFunctionalityStorage = (granted) => updateConsent('functionality_storage', granted);
    const updatePersonalizationStorage = (granted) => updateConsent('personalization_storage', granted);
    const updateSecurityStorage = (granted) => updateConsent('security_storage', granted);
  
    const handleAgreeAll = () => {
      [
        updateAdStorage,
        updateAdUserData,
        updateAdPersonalization,
        updateAnalyticsStorage,
        updateFunctionalityStorage,
        updatePersonalizationStorage,
        updateSecurityStorage
      ].forEach(updateFn => updateFn(true));
  
      updateCheckboxesFromStorage();
      showConsentOverlay(false);
      localStorage.setItem('sf-consentclosed', '1');
      unlockScroll();
    };
  
    const toggleDetails = () => {
      const detailsSection = document.getElementById("sfDetailsSection");
      detailsSection.style.display = detailsSection.style.display === "none" ? "block" : "none";
      document.getElementById("sfDetailsButton").classList.toggle("sf-closed");
      detailsSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
  
    const handleCloseDetails = () => {
      showConsentOverlay(false);
      localStorage.setItem('sf-consentclosed', '1');
      unlockScroll();
    };
  
    const updateCheckboxesFromStorage = () => {
      const consentMap = {
        "sfAdStorageToggle": "ad_storage",
        "sfAnalyticsStorageToggle": "analytics_storage",
        "sfAdUserDataToggle": "ad_user_data",
        "sfAdPersonalizationToggle": "ad_personalization",
        "sfFunctionalityStorageToggle": "functionality_storage",
        "sfPersonalizationStorageToggle": "personalization_storage",
        "sfSecurityStorageToggle": "security_storage",
      };
  
      Object.entries(consentMap).forEach(([id, storageKey]) => {
        const checkbox = document.getElementById(id);
        if (checkbox) {
          checkbox.checked = localStorage.getItem(storageKey) === 'true';
        }
      });
    };
  
    const showConsentOverlay = (show) => {
      document.getElementById("sfConsentOverlay").style.display = show ? "flex" : "none";
      document.getElementById("sfBackground").style.display = show ? "block" : "none";
    };
  
    const lockScroll = () => document.body.classList.add('sf-lockscroll');
    const unlockScroll = () => document.body.classList.remove('sf-lockscroll');

  return (
        <div>
          <div id="sfBackground" className="sf-background sf-closed"></div>
          <div className="sf-cons-overlay sf-closed" id="sfConsentOverlay">
            <div className="sf-cons">
              <p><strong>Ta strona używa plików cookie</strong></p>
              <p>Używamy plików cookie i możemy wykorzystywać je do: poprawy funkcjonalności strony, personalizacji treści lub reklam, funkcji mediów społecznościowych oraz analizy ruchu. Zebrane informacje przekazujemy zaufanym partnerom, którzy mogą łączyć je z innymi informacjami, które im dostarczasz w trakcie korzystania przez Ciebie z niniejszej strony, lub ich innych usług. Szczegółowe informacje znajdują się w <a href="/polityka-prywatnosci/">Polityce Prywatności</a>.</p>
              <p>Klikając "Wyrażam zgodę" akceptujesz wszystkie pliki cookie. Klikając przycisk "Ustawienia" możesz zdecydować na przetwarzanie jakiego rodzaju plików cookie wyrażasz zgodę. Znajdziesz tam również więcej informacji na temat poszczególnych typów ciasteczek.</p>
              <p>Podjęcie aktywnej decyzji jest konieczne do kontynuowania przeglądania strony i wynika z obowiązujących przepisów prawa oraz wymagań naszych partnerów.</p>
      
              <div id="sfDetailsSection" className="sf-medmargintop" style={{ display: 'none' }}>
                <p>Dostosuj swój wybór przez wskazanie na przetwarzanie których typów plików cookie wyrażasz zgodę. Przekażemy naszym partnerom informację na temat Twojego wyboru, dzięki czemu będą mogli dostosować działanie swoich narzędzi zgodnie z Twoją deklaracją.</p>
      
                {/* Essential Cookies */}
                <div className="sf-switchcontainer">
                <div className='sf-text'><strong>Pliki podstawowe</strong><br />
                  Niezbędne pliki cookie, potrzebne do funkcjonowania systemu zarządzania treścią. Ciasteczka niezbędne do poprawnego wyświetlania strony. Nie ma możliwości wycofania zgody na ich przetwarzanie.
                 </div>
                  <label className="sf-switch sf-disabled">
                    <input type="checkbox" className="sf-checkbox" checked disabled />
                    <span className="sf-switchslider"></span>
                  </label>
                </div>
      
                {/* Analytics Cookies */}
                <div className="sf-switchcontainer">
                <div className='sf-text'><strong>Pliki analityczne</strong><br />
                  Pliki wykorzystywane do analizy ruchu na stronie przez usługę Google Analytics. Pozwalają monitorować i analizować zachowania użytkowników. Celem tych ciasteczek jest zbieranie informacji o ruchu na stronie, interakcjach z treściami, wydajności witryny, a także pomaganie w optymalizacji doświadczeń użytkownika.
                  </div>
                  <label className="sf-switch">
                    <input type="checkbox" className="sf-checkbox" id="sfAnalyticsStorageToggle" />
                    <span className="sf-switchslider"></span>
                  </label>
                </div>
      
                {/* Ad Storage Cookies */}
                <div className="sf-switchcontainer">
                  <div className='sf-text'><strong>Pliki dotyczące reklam</strong><br />
                  Ciasteczka wykorzystywane do personalizacji treści reklamowych oraz zbierania danych dotyczących Twojego zachowania na stronie i na innych witrynach. Służą one do wyświetlania dopasowanych reklam.
                  </div>
                  <label className="sf-switch">
                    <input type="checkbox" className="sf-checkbox" id="sfAdStorageToggle" />
                    <span className="sf-switchslider"></span>
                  </label>
                </div>
      
                {/* User Data Cookies */}
                <div className="sf-switchcontainer">
                <div className='sf-text'><strong>Pliki dotyczące danych użytkownika</strong><br />
                  Pliki cookie umożliwiające zbieranie danych o Tobie, które mogą być wykorzystane do personalizacji treści i reklam. Umożliwiają także śledzenie Twoich działań na stronie.
                  </div>
                  <label className="sf-switch">
                    <input type="checkbox" className="sf-checkbox" id="sfAdUserDataToggle" />
                    <span className="sf-switchslider"></span>
                  </label>
                </div>
      
                {/* Ad Personalization Cookies */}
                <div className="sf-switchcontainer">
                <div className='sf-text'><strong>Pliki personalizujące reklamy</strong><br />
                  Ciasteczka używane do personalizowania reklam, które wyświetlane są na stronie. Są one dostosowane do Twoich wcześniejszych interakcji z witryną.
                  </div>
                  <label className="sf-switch">
                    <input type="checkbox" className="sf-checkbox" id="sfAdPersonalizationToggle" />
                    <span className="sf-switchslider"></span>
                  </label>
                </div>
      
                {/* Functionality Cookies */}
                <div className="sf-switchcontainer">
                <div className='sf-text'> <strong>Pliki funkcjonalne</strong><br />
                  Pliki pozwalające na dostosowanie funkcji strony internetowej w celu poprawy jakości doświadczenia użytkownika, np. zapamiętanie preferencji.
                  </div>
                  <label className="sf-switch">
                    <input type="checkbox" className="sf-checkbox" id="sfFunctionalityStorageToggle" />
                    <span className="sf-switchslider"></span>
                  </label>
                </div>
      
                {/* Personalization Cookies */}
                <div className="sf-switchcontainer">
                <div className='sf-text'><strong>Pliki personalizacyjne</strong><br />
                  Ciasteczka wykorzystywane do personalizacji treści na stronie, dostosowując je do Twoich preferencji i zainteresowań.
                  </div>
                  <label className="sf-switch">
                    <input type="checkbox" className="sf-checkbox" id="sfPersonalizationStorageToggle" />
                    <span className="sf-switchslider"></span>
                  </label>
                </div>
      
                {/* Security Cookies */}
                <div className="sf-switchcontainer">
                <div className='sf-text'><strong>Pliki dotyczące bezpieczeństwa</strong><br />
                  Ciasteczka umożliwiające zapewnienie bezpieczeństwa użytkownika, np. przez ochronę przed nieautoryzowanym dostępem.
                  </div>
                  <label className="sf-switch">
                    <input type="checkbox" className="sf-checkbox" id="sfSecurityStorageToggle" />
                    <span className="sf-switchslider"></span>
                  </label>
                </div>
      
                <button id="sfCloseDetailsButton" className="uk-button uk-button-default uk-button-small">Zapisz</button>
              </div>
              <div className="sf-button-container">
              <button id="sfDetailsButton" className="uk-button uk-button-default uk-button-small">Ustawienia</button>
              <button id="sfAgreeAllButton" className="uk-button uk-button-primary uk-button-small">Wyrażam zgodę</button>
            </div>
            </div>
          </div>
        </div>
  )
};

export default SFContent;
