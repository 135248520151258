import React from 'react';
import Card from './Card';
import plyty_warstwowe from './config/PlytyWarstwoweConfig';

const PlytyWarstwowe = () => (
  <div>
    <h1>Płyty warstwowe</h1>
          <p>
            Płyty warstwowe to linia płyt, które łączą w sobie
            najnowocześniejsze rozwiązania technologiczne z doskonałą
            wydajnością izolacyjną. Dzięki zastosowaniu innowacyjnych
            materiałów, płyty te są idealnym wyborem do różnorodnych zastosowań
            budowlanych. Oferują niezrównaną ochronę przed utratą ciepła,
            wilgocią oraz zmieniającymi się warunkami atmosferycznymi. 
            <ul><h3>W ofercie posiadamy płyty:</h3>
                <li> <b>Płyty warstwowe ścienne</b> - przeznaczone do konstrukcji ścian zewnętrznych</li> 
                <li><b>Płyty warstwowe dachowe</b> - doskonale nadające się do krycia obiektów przemysłowych i magazynowych</li>
            <li> <b>Płyty warstwowe chłodnicze</b> -  przeznaczone do obiektów, w których temperatura wynosi
            do -40 stopni Celsjusza </li>
            </ul>
          </p>

    <h1>Oferta:</h1>
    <div className="product-list">
      {plyty_warstwowe.map((product, index) => (
        <Card key={index} product={product} />
      ))}
    </div>
  </div>
);

export default PlytyWarstwowe;
